$(document).ready(function () {
  // PARENTS
  // Function to calculate the next unique index
  function getNextParentIndex() {
    let maxIndex = 0;

    // Find the highest existing index in the form
    $('#parents-fields input[name*="parents_attributes"]').each(function () {
      const match = $(this).attr('name').match(/\[(\d+)\]/); // Extract the index
      if (match && match[1]) {
        maxIndex = Math.max(maxIndex, parseInt(match[1], 10));
      }
    });

    return maxIndex + 1; // Return the next index
  }

  // Add new parent
  $('#add-parent').on('click', function (e) {
    e.preventDefault();

    const parentIndex = getNextParentIndex();

    // Clone the hidden template
    const newParent = $('#parent-template').html();

    // Replace the placeholder with a unique index
    const updatedParent = newParent.replace(/NEW_RECORD/g, parentIndex);

    // Append to the parents fields container
    $('#parents-fields').append(updatedParent);
  });

  // Remove parent field
  $(document).on('click', '.remove-parent', function (e) {
    e.preventDefault();

    // Show a confirmation popup
    const confirmed = confirm("Are you sure you want to remove this parent?");

    // If the user confirms, remove the parent `.parent-field` div
    if (confirmed) {
      $(this).closest('.parent-field').remove();
    }
  });

  // admin date helpers
  $(".set-date").on("click", function(e) {
    e.preventDefault();
    var days = $(this).data("days");
    var futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + days);
    $("input[name$='[update_required_on]']").val(futureDate.toISOString().split('T')[0]);
  });

  // Add new website
  $('#add-website').on('click', function (e) {
    e.preventDefault();

    // Clone the hidden template
    const newwebsite = $('#website-template').html();

    // Append to the websites fields container
    $('#websites-fields').append(newwebsite);
  });

  // Add new email
  $('#add-email').on('click', function (e) {
    e.preventDefault();

    // Clone the hidden template
    const newemail = $('#email-template').html();

    // Append to the emails fields container
    $('#emails-fields').append(newemail);
  });

  // Add new fax_number
  $('#add-fax_number').on('click', function (e) {
    e.preventDefault();

    // Clone the hidden template
    const newfax_number = $('#fax_number-template').html();

    // Append to the fax_numbers fields container
    $('#fax_numbers-fields').append(newfax_number);
  });

  // Add new phone_number
  $('#add-phone_number').on('click', function (e) {
    e.preventDefault();

    // Clone the hidden template
    const newphone_number = $('#phone_number-template').html();

    // Append to the phone_numbers fields container
    $('#phone_numbers-fields').append(newphone_number);
  });

  // Remove templates before form submission
  $('form').on('submit', function () {
    $('#phone_number-template').remove();
    $('#fax_number-template').remove();
    $('#email-template').remove();
    $('#website-template').remove();
  });

  // dynamic set emp range select
  var employeeRanges = [
    { code: 0, low: 0, high: 0 },
    { code: 1, low: 1, high: 5 },
    { code: 2, low: 6, high: 10 },
    { code: 3, low: 11, high: 20 },
    { code: 4, low: 21, high: 50 },
    { code: 5, low: 51, high: 100 },
    { code: 6, low: 101, high: 250 },
    { code: 7, low: 251, high: 500 },
    { code: 8, low: 501, high: 1000 },
    { code: 9, low: 1001, high: 5000 },
    { code: 10, low: 5001, high: 1000000 }
  ];

  $('#organisation_employee_count').on('input', function () {
    var count = parseInt($(this).val(), 10) || 0;
    var matchingRange = employeeRanges.find(range => count >= range.low && count <= range.high);
    if (matchingRange) {
      $('#organisation_employee_range_code').val(matchingRange.code);
    }
  });

  // anzsic code multiselect
  $('#organisation_anzsic_code').multiselect({
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    maxHeight: 200,
    buttonWidth: '100%',
    includeSelectAllOption: false,
    filterPlaceholder: 'Search ANZSIC Codes...',
    numberDisplayed: 0
  });

  // import and export countries multiselect
  $('#organisation_import_country_codes, #organisation_export_country_codes').multiselect({
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    maxHeight: 200,
    buttonWidth: '100%',
    includeSelectAllOption: false,
    filterPlaceholder: 'Search Countries...',
    numberDisplayed: 4
  });

  $('#organisation_region_code').multiselect({
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    maxHeight: 200,
    includeSelectAllOption: false,
    filterPlaceholder: 'Select region...',
    numberDisplayed: 0
  });

});
