$(document).ready(function () {
   // Function to calculate the next unique index
   function getNextBankIndex() {
    let maxIndex = 0;

    // Find the highest existing index in the form
    $('#banks-fields input[name*="banks"]:visible').each(function () {
      const match = $(this).attr('id').match(/(\d+)/); // Extract the index
      // debugger;
      if (match && match[1]) {
        maxIndex = Math.max(maxIndex, parseInt(match[1], 10));
      }
    });

    return maxIndex + 1; // Return the next index
  }

  // Add new bank
  function addBank(beforeElement) {
    const bankIndex = getNextBankIndex();

    // Clone the hidden template
    const newBank = $('#bank-template').html();

    const updatedBank = newBank.replace(/NEW_RECORD/g, bankIndex);

    // Append to the banks fields container
    if (beforeElement && beforeElement.length) {
      $(beforeElement).before(updatedBank);
    } else {
      $('#banks-fields').append(updatedBank);
    }

    var substringMatcher = function(strs) {
      return function findMatches(q, cb) {
        var matches, substringRegex;

        // an array that will be populated with substring matches
        matches = [];

        // regex used to determine if a string contains the substring `q`
        substrRegex = new RegExp(q, 'i');

        // iterate through the pool of strings and for any string that
        // contains the substring `q`, add it to the `matches` array
        $.each(strs, function(i, str) {
          if (substrRegex.test(str)) {
            matches.push(str);
          }
        });

        cb(matches);
      };
    };

    var banks = [
      'ANZ Banking Group (New Zealand) Limited',
      'ASB Bank Limited',
      'Bank of New Zealand',
      'Citibank N.A.',
      'HSBC',
      'KiwiBank',
      'Rabobank',
      'TSB Bank Limited',
      'Westpac'
    ];

    $('#banks-fields input[name*="banks"]:visible').each(function () {
      $(this).typeahead({
        hint: true,
        highlight: true,
        minLength: 1
      },
      {
        name: 'banks',
        source: substringMatcher(banks)
      });
    });
  }

  // Event handler for add-bank-here button
  $(document).on('click', '.add-bank-here', function (e) {
    e.preventDefault();
    addBank($(this).closest('.bank-field').next('.bank-field'));
  });

  // Remove bank field
  $(document).on('click', '.remove-bank', function (e) {
    e.preventDefault();

    // generate a string of the bank's name from the parent element
    const bankName = $(this).closest('.bank-field').find('input[name*="[banks]"]').val() || '';

    // don't show the popup if bankname is blank
    if (!bankName) {
      $(this).closest('.bank-field').remove();
      return;
    }

    // Show a confirmation popup using the generated bank's name
    const confirmed = confirm(`Are you sure you want to remove: ${bankName}?`);

    // If the user confirms, remove the parent `.bank-field` div
    if (confirmed) {
      $(this).closest('.bank-field').remove();
    }
  });

  // Remove bank-template before form submission
  $('form').on('submit', function () {
    $('#bank-template').remove();
  });
});
