$(document).on('click', 'button#submit_export', function(event) {
  event.preventDefault();
  return $(this).closest('.modal').find('form').submit();
});

window.ExportFieldSelection = (function() {
  ExportFieldSelection.init = function(selector) {
    return new this(selector);
  };

  function ExportFieldSelection(selector) {
    this.field = $(selector);
    this.field.on('change', function(e) {
      if ($('#export_cost_paid').prop('checked')) {
        $('#export_fields option').prop('selected', true);
        $('#export_fields').prop('disabled', false);
        $('#export_contact_limit').prop('disabled', false);
        return $('#export_contact_limit option').filter('[value="99"]').prop('selected', true);
      } else {
        $('#export_fields option').prop('selected', false);
        $('#export_fields option').filter('[value="id"]').prop('selected', true);
        $('#export_fields option').filter('[value="name"]').prop('selected', true);
        $('#export_fields').prop('disabled', true);
        $('#export_contact_limit').prop('disabled', true);
        return $('#export_contact_limit option').filter('[value="0"]').prop('selected', true);
      }
    });
    this.field.trigger('change');
  }

  return ExportFieldSelection;

})();

document.addEventListener('DOMContentLoaded', function() {
  ExportFieldSelection.init("input[name='export[cost]']:radio");
});
