$(function() {
  $('#trial-requests-table').dataTable({
    processing: true,
    serverSide: true,
    ajax: $('#trial-requests-table').data('source'),
    pagingType: 'full_numbers',
    columns: [
      { data: 'name' },
      { data: 'country' },
      { data: 'contact' },
      { data: 'email' },
      { data: 'phone', orderable: false },
      { data: 'usage', orderable: false },
      { data: 'created' },
      { data: 'subscriber', orderable: false },
      { data: 'searches', orderable: false }
    ],
    order: [[6, "desc"]]
  });
});
