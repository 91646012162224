$(document).on('click', '#set_trial_dates', function(event) {
  event.preventDefault();
  const today = new Date();
  // Set the start date to 12 hours from UTC now
  const startsOn = new Date(today.getTime() + (12 * 60 * 60 * 1000)).toISOString().split('T')[0];
  const endsOn = new Date(today.setDate(today.getDate() + 14)).toISOString().split('T')[0];

  $('#subscriber_subscription_starts_on').val(startsOn);
  $('#subscriber_subscription_ends_on').val(endsOn);
  $('#subscriber_trial').prop('checked', true);
  $('#subscriber_trial_search_count_limit').val('300');
});

$(function() {
  $('#subscribers-table').dataTable({
    processing: true,
    serverSide: true,
    ajax: $('#subscribers-table').data('source'),
    pagingType: 'full_numbers',
    columns: [
      { data: 'name' },
      { data: 'from' },
      { data: 'to' },
      { data: 'current', orderable: false },
      { data: 'searches' },
      { data: 'admin', orderable: false }
    ],
    order: [[2, "desc"]]
  });
});
