$(document).ready(function () {
  // Add new member
  function addMember(beforeElement) {
    // Clone the hidden template
    const newMember = $('#member-template').html();

    // Append to the members fields container
    if (beforeElement && beforeElement.length) {
      $(beforeElement).before(newMember);
    } else {
      $('#members-fields').append(newMember);
    }
  }

  // Event handler for add-member-here button
  $(document).on('click', '.add-member-here', function (e) {
    e.preventDefault();
    addMember($(this).closest('.member-field').next('.member-field'));
  });

  // Remove member field
  $(document).on('click', '.remove-member', function (e) {
    e.preventDefault();

    // generate a string of the member's name from the parent element
    const memberName = $(this).closest('.member-field').find('input[name*="[members]"]').val() || '';

    // don't show the popup if membername is blank
    if (!memberName) {
      $(this).closest('.member-field').remove();
      return;
    }

    // Show a confirmation popup using the generated member's name
    const confirmed = confirm(`Are you sure you want to remove: ${memberName}?`);

    // If the user confirms, remove the parent `.member-field` div
    if (confirmed) {
      $(this).closest('.member-field').remove();
    }
  });

  // Remove member-template before form submission
  $('form').on('submit', function () {
    $('#member-template').remove();
  });
});
