$(document).ready(function () {
  // Function to calculate the next unique index
  function getNextTurnoverIndex() {
    let maxIndex = 0;

    // Find the highest existing index in the form
    $('#turnovers-fields input[name*="turnovers_attributes"]').each(function () {
      const match = $(this).attr('name').match(/\[(\d+)\]/); // Extract the index
      if (match && match[1]) {
        maxIndex = Math.max(maxIndex, parseInt(match[1], 10));
      }
    });

    return maxIndex + 1; // Return the next index
  }

  // Add new turnover
  function addTurnover(beforeElement) {
    const turnoverIndex = getNextTurnoverIndex();

    // Clone the hidden template
    const newTurnover = $('#turnover-template').html();

    // Replace the placeholder with a unique index
    const updatedTurnover = newTurnover.replace(/NEW_RECORD/g, turnoverIndex);

    // Append to the turnovers fields container
    if (beforeElement && beforeElement.length) {
      $(beforeElement).before(updatedTurnover);
    } else {
      $('#turnovers-fields').append(updatedTurnover);
    }

    // Add buttons for adding turnovers
    addTurnoverButtons();
  }

  // Add buttons for adding turnovers
  function addTurnoverButtons() {
    // Remove existing "Add Turnover Here" buttons
    $('.add-turnover-here').remove();

    const addTurnoverButton = '<button class="btn btn-primary add-turnover-here"><i class="bi-plus"></i><i class="bi-currency-dollar"></i></button>';

    // Add "Add Turnover Here" button before the first turnover
    $('#turnovers-fields').prepend(addTurnoverButton);

    // Add "Add Turnover Here" button after each turnover
    $('.turnover-field').each(function () {
      $(this).after(addTurnoverButton);
    });
  }

  // Initial call to add buttons
  addTurnoverButtons();

  // Event handler for add-turnover-here button
  $(document).on('click', '.add-turnover-here', function (e) {
    e.preventDefault();
    addTurnover($(this).next('.turnover-field'));
  });

  // Remove turnover field
  $(document).on('click', '.remove-turnover', function (e) {
    e.preventDefault();

    // generate a string of the turnover from the parent element
    const amount = $(this).closest('.turnover-field').find('input[name*="[amount]"]').val() || '';
    const currency = $(this).closest('.turnover-field').find('input[name*="[currency]"]').val() || '';
    const turnoverName = `${amount} ${currency}`.trim();

    // Show a confirmation popup using the generated turnover's name
    const confirmed = confirm("Are you sure you want to remove: " + turnoverName + "?");

    // If the user confirms, remove the parent `.turnover-field` div
    if (confirmed) {
      $(this).closest('.turnover-field').remove();
      // Re-add the "Add Turnover Here" buttons
      addTurnoverButtons();
    }
  });

  // dynamic set turnover range selection
  var turnoverRanges = [
    { code: 0, low: 0, high: 0 },
    { code: 1, low: 1, high: 1000000 },
    { code: 2, low: 1000000, high: 5000000 },
    { code: 3, low: 5000000, high: 10000000 },
    { code: 4, low: 10000000, high: 20000000 },
    { code: 5, low: 20000000, high: 50000000 },
    { code: 6, low: 50000001, high: 999999999999 }
  ];

  // Event delegation for input fields
  $(document).on('input', 'input[name*="organisation[turnovers_attributes]"][name*="[amount]"]', function () {
    var turnover = parseInt($(this).val()) || 0;
    var matchingRange = turnoverRanges.find(range => turnover >= range.low && turnover < range.high);
    if (matchingRange) {
      $(this).closest('.turnover-field').find('select[name*="[range_code]"]').val(matchingRange.code);
    }
  });
});
