$(function() {
  $('#payments-table').dataTable({
    processing: true,
    serverSide: true,
    ajax: $('#payments-table').data('source'),
    pagingType: 'full_numbers',
    columns: [
      { data: 'id' },
      { data: 'received', orderable: false },
      { data: 'total', orderable: false },
      { data: 'user', orderable: false },
      { data: 'created', orderable: false }
    ],
    order: [[0, "desc"]]
  });
});
