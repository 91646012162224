$(document).ready(function () {
  // Function to calculate the next unique index
  function getNextContactIndex() {
    let maxIndex = 0;

    // Find the highest existing index in the form
    $('#contacts-fields input[name*="contacts_attributes"]').each(function () {
      const match = $(this).attr('name').match(/\[(\d+)\]/); // Extract the index
      if (match && match[1]) {
        maxIndex = Math.max(maxIndex, parseInt(match[1], 10));
      }
    });

    return maxIndex + 1; // Return the next index
  }

  // Add new contact
  function addContact(beforeElement) {
    const contactIndex = getNextContactIndex();

    // Clone the hidden template
    const newContact = $('#contact-template').html();

    // Replace the placeholder with a unique index
    const updatedContact = newContact.replace(/NEW_RECORD/g, contactIndex);

    // Append to the contacts fields container
    if (beforeElement && beforeElement.length) {
      $(beforeElement).before(updatedContact);
    } else {
      $('#contacts-fields').append(updatedContact);
    }

    // Add buttons for adding contacts
    addContactButtons();
  }

  // Add buttons for adding contacts
  function addContactButtons() {
    // Remove existing "Add Contact Here" buttons
    $('.add-contact-here').remove();

    const addContactButton = '<button class="btn btn-primary add-contact-here"><i class="bi-plus"></i><i class="bi-user"></i></button >';

    // Add "Add Contact Here" button before the first contact
    $('#contacts-fields').prepend(addContactButton);

    // Add "Add Contact Here" button after each contact
    $('.contact-field').each(function () {
      $(this).after(addContactButton);
    });

    // Initialize multiselect for job titles, ignoring the hidden templates
    $('select[id$="job_title_code"]:visible').multiselect({
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true,
      maxHeight: 200,
      buttonWidth: '100%',
      includeSelectAllOption: false,
      filterPlaceholder: 'Search Job Titles...',
      numberDisplayed: 0
    });
  }

  // Initial call to add buttons
  addContactButtons();

  // Event handler for add-contact-here button
  $(document).on('click', '.add-contact-here', function (e) {
    e.preventDefault();
    addContact($(this).next('.contact-field'));
  });

  // Remove contact field
  $(document).on('click', '.remove-contact', function (e) {
    e.preventDefault();

    // generate a string of the contact's name from the parent element including salutation, first and last name
    const salutation = $(this).closest('.contact-field').find('select[name*="[salutation_code]"] option:selected').text() || '';
    const firstName = $(this).closest('.contact-field').find('input[name*="[first_name]"]').val() || '';
    const lastName = $(this).closest('.contact-field').find('input[name*="[last_name]"]').val() || '';
    const contactName = `${salutation} ${firstName} ${lastName}`.trim();

    // Show a confirmation popup using the generated contact's name

    const confirmed = confirm("Are you sure you want to remove: " + contactName + "?");

    // If the user confirms, remove the parent `.contact-field` div
    if (confirmed) {
      $(this).closest('.contact-field').remove();
      // Re-add the "Add Contact Here" buttons
      addContactButtons();
    }
  });
});
