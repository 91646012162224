$(document).ready(function() {
  $("form:first input[type!='hidden']:first").focus();

  var company_name_auto = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: "/autocomplete/company_name/%QUERY",
      wildcard: '%QUERY'
    }
  });
  company_name_auto.initialize();
  $("#company_name.typeahead").typeahead(null, {
    source: company_name_auto,
    display: 'value'
  });

  if ($('#form_search').length > 0) {
    new ComboWarning('#form_search', ['#turnover_range', '#employee_range'], '#turnover_emp_combo');
    new SearchDescriptionUpdater('#form_search');
    new ComboWarning('#form_search', ['#job_title', '#job_title_category'], '#job_title_combo');
    new MultiSelectBoxes('#form_search', [
      '#employee_range',
      '#turnover_range',
      '#region',
      '#anzsic_division',
      '#anzsic_subdivision',
      '#anzsic_group',
      '#anzsic_class',
      '#job_title_category',
      '#job_title',
      '#export_countries',
      '#import_countries',
      '#parent_country',
      '#include_lists',
      '#exclude_lists',
      '#banks',
      '#members',
      '#update_date',
      '#status'
    ]);
  };

  return;
});

class ComboWarning {
  constructor(form, comboBoxes, warning) {
    this.form = form;
    this.comboBoxes = comboBoxes;
    this.warning = warning;
    this.comboBoxes.forEach((comboBox) => {
      $(comboBox).on('change', (e) => {
      this.toggleWarning();
      });
    });
    $(this.form).on('reset', (e) => {
      setTimeout(() => {
        this.toggleWarning();
      });
    });
  }

  toggleWarning() {
    var has_values = this.comboBoxes.map((comboBox) => {
      return $(comboBox).val() && $(comboBox).val().length > 0;
    });
    var warning_visible = has_values.filter(Boolean).length > 1;
    $(this.warning).toggle(warning_visible);
  }
}

class SearchDescriptionUpdater {
  constructor(form) {
    this.form = form;

    $(this.form + " :input").on('change', (e) => {
      this.updateSearchDescription();
    });
    $(this.form).on('reset', (e) => {
      setTimeout(() => {
        this.updateSearchDescription();
      });
    });
    this.updateSearchDescription();
  }

  updateSearchDescription() {
    $('#search_description').html('');
    var activeFilters = [];

    var excludedFields = ['missing_anzsic', 'update_date[]', 'status[]']; // Add field names or IDs to exclude
    $(this.form + " :input").each(function() {
      // Skip excluded fields
      if (excludedFields.includes($(this).attr('name'))) return;

      var fieldIsActive = $(this).is(':checkbox') ?
        $(this).prop('checked') && !$(this).prop('disabled') :
        $(this).is('select[multiple]') ?
          $(this).val() && $(this).val().length > 0 :
          $(this).val();
      var fieldDesc = $(this).data('desc');
      if (fieldIsActive && jQuery.trim(fieldDesc).length > 0) {
        activeFilters.push(fieldDesc);
      }
    });

    activeFilters = [...new Set(activeFilters)];
    if (activeFilters.length > 0) {
      $('#search_description').append(activeFilters.join(', '));
    } else {
      $('#search_description').append("None");
    }
  }
}

class MultiSelectBoxes {
  constructor(form, boxes) {
    this.form = form;
    this.boxes = boxes;
    this.boxes.forEach((box) => {
      $(box).multiselect({
      maxHeight: 300,
      includeSelectAllOption: true,
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true
      });
    });
  }
}

$(document).on('click', '#form_search_reset', function(e) {
  e.preventDefault();
  $('#form_search')[0].reset();

  // Reset multi-select dropdowns
  $('select').each(function() {
    $(this).multiselect('deselectAll', false);
    if ($(this).attr('name') === 'status[]') {
      $(this).multiselect('select', ['active', 'manual_update']);
    }
    $(this).multiselect('updateButtonText');
  });

  return;
});

$(document).on('click', 'a[data-anzsic-2006-link]', function(e) {
  e.preventDefault();
  $('#form_search_reset').click();
  $('#business').html('');
  $('.nav-tabs a[href="#form_products"]').tab('show');
  var anzsicCode = $(this).data('anzsic-2006-link');
  var option = 'option[value="' + anzsicCode + '"]';
  if (anzsicCode.length == 1) {
    $(option, $('#anzsic_division')).prop('selected', true);
    $('#anzsic_division').multiselect('refresh');
  } else if (anzsicCode.length == 3) {
    $(option, $('#anzsic_subdivision')).prop('selected', true);
    $('#anzsic_subdivision').multiselect('refresh');
  } else if (anzsicCode.length == 4) {
    $(option, $('#anzsic_group')).prop('selected', true);
    $('#anzsic_group').multiselect('refresh');
  } else if (anzsicCode.length == 5) {
    $(option, $('#anzsic_class')).prop('selected', true);
    $('#anzsic_class').multiselect('refresh');
  }
  $('#form_search').submit();
  return;
});
