$(document).ready(function () {
  // Function to calculate the next unique index
  function getNextDirectorIndex() {
    let maxIndex = 0;

    // Find the highest existing index in the form
    $('#directors-fields input[name*="directors_attributes"]').each(function () {
      const match = $(this).attr('name').match(/\[(\d+)\]/); // Extract the index
      if (match && match[1]) {
        maxIndex = Math.max(maxIndex, parseInt(match[1], 10));
      }
    });

    return maxIndex + 1; // Return the next index
  }

  // Add new director
  function addDirector(beforeElement) {
    const directorIndex = getNextDirectorIndex();

    // Clone the hidden template
    const newDirector = $('#director-template').html();

    // Replace the placeholder with a unique index
    const updatedDirector = newDirector.replace(/NEW_RECORD/g, directorIndex);

    // Append to the directors fields container
    if (beforeElement && beforeElement.length) {
      $(beforeElement).before(updatedDirector);
    } else {
      $('#directors-fields').append(updatedDirector);
    }

    // Add buttons for adding directors
    addDirectorButtons();
  }

  // Add buttons for adding directors
  function addDirectorButtons() {
    // Remove existing "Add Director Here" buttons
    $('.add-director-here').remove();

    const addDirectorButton = '<button class="btn btn-primary add-director-here"><i class="bi-plus"></i><i class="bi-user"></i></button >';

    // Add "Add Director Here" button before the first director
    $('#directors-fields').prepend(addDirectorButton);

    // Add "Add Director Here" button after each director
    $('.director-field').each(function () {
      $(this).after(addDirectorButton);
    });

    // Initialize multiselect for job titles, ignoring the hidden templates
    $('select[id$="job_title_code"]:visible').multiselect({
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true,
      maxHeight: 200,
      buttonWidth: '100%',
      includeSelectAllOption: false,
      filterPlaceholder: 'Search Job Titles...',
      numberDisplayed: 0
    });
  }

  // Initial call to add buttons
  addDirectorButtons();

  // Event handler for add-director-here button
  $(document).on('click', '.add-director-here', function (e) {
    e.preventDefault();
    addDirector($(this).next('.director-field'));
  });

  // Remove director field
  $(document).on('click', '.remove-director', function (e) {
    e.preventDefault();

    // generate a string of the director's name from the parent element including salutation, first and last name
    const salutation = $(this).closest('.director-field').find('select[name*="[salutation_code]"] option:selected').text() || '';
    const firstName = $(this).closest('.director-field').find('input[name*="[first_name]"]').val() || '';
    const lastName = $(this).closest('.director-field').find('input[name*="[last_name]"]').val() || '';
    const directorName = `${salutation} ${firstName} ${lastName}`.trim();

    // Show a confirmation popup using the generated director's name

    const confirmed = confirm("Are you sure you want to remove: " + directorName + "?");

    // If the user confirms, remove the parent `.director-field` div
    if (confirmed) {
      $(this).closest('.director-field').remove();
      // Re-add the "Add Director Here" buttons
      addDirectorButtons();
    }
  });
});
